
import ApiService from '@/core/services/ApiService';
import { useRoute } from 'vue-router';
import { computed, onMounted, reactive, toRefs } from '@vue/runtime-core';
import { dateFormat, numberFormat, mappedStatus } from '@/utils/helper/format';
import { ServicesTypes } from '@/core/data/constance';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';

export default {
  name: 'ViewSalesReport',
  setup() {
    const route = useRoute();
    const { id } = route.params;
    const state = reactive({
      loaded: false,
      orderData: {
        id: null,
        amountPrice: 0,
        car: {},
        customer: {},
        startDate: null,
        endDate: null,
        partner: {},
        payment: {},
        servicePoint: { name: '', id: '' },
        status: null,
        createdAt: null,
        package: {
          id: null,
        },
        store: {
          id: null,
        },
      },
    });

    async function fetch() {
      const { data } = await ApiService.get(`order/${id}`);
      state.orderData.amountPrice = data.data.amountPrice;
      state.orderData.car = data.data.car;
      state.orderData.createdAt = data.data.createdAt;
      state.orderData.customer = data.data.customer;
      state.orderData.endDate = data.data.endDate;
      state.orderData.id = data.data.id;
      state.orderData.package = data.data.package || {};
      state.orderData.partner = data.data.partner;
      state.orderData.payment = data.data.payment;
      state.orderData.servicePoint = data.data.servicePoint;
      state.orderData.startDate = data.data.startDate;
      state.orderData.status = data.data.status;
      state.orderData.store = data.data.store;
    }

    onMounted(async () => {
      setCurrentPageBreadcrumbs('Dashboard', ['Report'], {});
      await fetch();
      state.loaded = true;
    });

    return {
      dateFormat,
      numberFormat,
      ...toRefs(state),
      mappedStatus,
      packageName: computed(() => {
        return (
          ServicesTypes.find((p) => p.value == state.orderData.package?.id)
            ?.name || 'N/A'
        );
      }),
    };
  },
};
